globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"c0b36eedc41cabaa214de048119b0a921841c623"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || 'development'
const SENTRY_TRACES_SAMPLE_RATE = parseInt(
  process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE ?? '1.0'
)

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

  replaysSessionSampleRate: SENTRY_ENVIRONMENT === 'production' ? 0.1 : 0,
  replaysOnErrorSampleRate: 1.0,

  integrations: [Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true })],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  initialScope: {
    tags: {
      'vercel.host': process.env.NEXT_PUBLIC_VERCEL_URL ?? 'No host found',
    },
  },
  // Ignore specific errors
  ignoreErrors: [
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    'ResizeObserver loop limit exceeded',
    // https://github.com/getsentry/sentry-javascript/issues/2514
    'Non-Error promise rejection captured',
    // https://github.com/SamsungInternet/support/issues/56
    'document.getElementsByClassName.ToString',
    /Blocked a frame with origin/,
    /ibFindAllVideos/,
    /vid_mate_check is not defined/,
  ],
  // Ignore these urls that may throw errors
  denyUrls: [
    // Ignore errors in tiq tags
    // Can happen if users have adblocker on
    /tags\.tiqcdn\.com/i,
    // Marketo
    /munchkin\.marketo\.net/i,
    // Ignore Tohu errors
    /storage\.faqbot\.nz/i,
    /portal\.faqbot\.nz/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Google Adsense
    /pagead\/js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Safari extensions
    /safari-extension\//i,
  ],
})
