import { AutocompleteApi, AutocompleteState } from '@algolia/autocomplete-core'
import { Box, BoxProps, TextProps, useToken } from '@chakra-ui/react'
import { forwardRef, ReactElement, ReactNode } from 'react'

import { ZINDEX } from 'utils'
import { H5 } from '../../typography'
import { AutocompleteResults } from './SearchAutocomplete'

interface SearchAutocompletePanel {
  title: string
  autocompleteState: AutocompleteState<AutocompleteResults>
  autocomplete: AutocompleteApi<AutocompleteResults>
  children: ReactNode
  titleProps?: TextProps
  contentWrapperProps?: BoxProps
}

const SearchAutocompletePanel = forwardRef<
  HTMLDivElement,
  SearchAutocompletePanel
>(
  (
    {
      autocomplete,
      autocompleteState,
      title,
      titleProps,
      contentWrapperProps,
      children
    },
    ref
  ): ReactElement => {
    const inputHeight = 100
    const [space8] = useToken('space', ['8'])
    return (
      //@ts-ignore
      <Box
        ref={ref}
        {...autocomplete.getPanelProps({})}
        zIndex={1}
        width='100%'
      >
        {autocompleteState.isOpen && (
          <Box
            mt={'3px'}
            backgroundColor='white'
            boxShadow={{ md: '0px 2px 10px 0px rgba(0,0,0,0.15)' }}
            borderRadius='base'
            p={4}
            maxHeight={{ base: `calc(100vh - ${inputHeight}px)`, md: 'auto' }}
            position={{ base: 'relative', md: 'absolute' }}
            width={{ base: `calc(100% + ${space8})`, md: '100%' }}
            top='100%'
            left={{ base: -4, md: 'unset' }}
            zIndex={ZINDEX.SEARCH_PANEL}
            sx={{
              'html[lang=ar] &': {
                left: 'unset'
              }
            }}
            {...contentWrapperProps}
          >
            <H5 letterSpacing={0} {...titleProps}>
              {title}
            </H5>
            {children}
          </Box>
        )}
      </Box>
    )
  }
)

SearchAutocompletePanel.displayName = 'SearchAutocompletePanel'
export default SearchAutocompletePanel
