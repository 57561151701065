import ArrowIcon from "./arrow.svg";
import BlogIcon from "./blogIcon.svg";
import CalendarIcon from "./calendarIcon.svg";
import ChatIcon from "./chatIcon.svg";
import ChevronCircle from "./chevronCircle.svg";
import ChevronCircleLarge from "./chevronCircleLarge.svg";
import ChevronIcon from "./chevronIcon.svg";
import ContactIcon from "./contactIcon.svg";
import BackIcon from "./createIcons/BackIcon";
import CloseIcon from "./createIcons/CloseIcon";
import MinusIcon from "./createIcons/MinusIcon";
import CrossIcon from "./cross.svg";
import DashboardEnquiryIcon from "./dashboardEnquiryIcon.svg";
import DashboardEnquiryListIcon from "./dashboardEnquiryListIcon.svg";
import DashboardRecommendationsCardIcon from "./dashboardRecommendationsCardIcon.svg";
import DashboardRecommendationsCardIncompleteIcon from "./dashboardRecommendationsCardIncompleteIcon.svg";
import DashboardSavedIcon from "./dashboardSavedIcon.svg";
import DashboardSavedListIcon from "./dashboardSavedListIcon.svg";
import EnquiredIcon from "./enquiredIcon.svg";
import EnquiryCircle from "./enquiryCircle.svg";
import EnquiryIcon from "./enquiryIcon.svg";
import ExpandRoundedIcon from "./expandRoundedIcon.svg";
import ExternalLinkIcon from "./externalLinkicon.svg";
import FacebookIcon from "./facebookIcon.svg";
import FavouriteCircle from "./favouriteCircle.svg";
import FavouritedIcon from "./favouritedIcon.svg";
import FavouriteIcon from "./favouriteIcon.svg";
import FilterIcon from "./filterIcon.svg";
import FlagARIcon from "./flagARIcon.svg";
import FlagCNIcon from "./flagCNIcon.svg";
import FlagESIcon from "./flagESIcon.svg";
import FlagJAIcon from "./flagJAIcon.svg";
import FlagKOIcon from "./flagKOIcon.svg";
import FlagNZIcon from "./flagNZIcon.svg";
import FlagPTIcon from "./flagPTIcon.svg";
import FlagTHIcon from "./flagTHIcon.svg";
import FlagVNIcon from "./flagVNIcon.svg";
import HamburgerMenuIcon from "./hamburgerMenuIcon.svg";
import HidePasswordIcon from "./hidePasswordIcon.svg";
import InfoIcon from "./infoIcon.svg";
import InstagramIcon from "./instagramIcon.svg";
import LightBulbIcon from "./lightBulbIcon.svg";
import LocationIcon from "./locationIcon.svg";
import LoginPrompt from "./loginPromptPreview.svg";
import LoginPromptMobile from "./loginPromptPreviewMobile.svg";
import LogoutIcon from "./logoutIcon.svg";
import MapLocationIcon from "./mapLocationIcon.svg";
import NaverIcon from "./naverIcon.svg";
import NZGovtLogo from "./nzGovt.svg";
import NZMapIcon from "./nzMapIcon.svg";
import PlayIcon from "./playIcon.svg";
import PlayIconSolid from "./playIconSolid.svg";
import PlusIcon from "./plusIcon.svg";
import QuoteIcon from "./quoteIcon.svg";
import RecommendationsIcon from "./recommendationsIcon.svg";
import SavedIcon from "./savedIcon.svg";
import SavedPrompt from "./savedPrompt.svg";
import SavedPromptMobile from "./savedPromptMobile.svg";
import ScrollIcon from "./scrollIcon.svg";
import SearchIcon from "./searchIcon.svg";
import ShowPasswordIcon from "./showPasswordIcon.svg";
import TickIcon from "./tickIcon.svg";
import TickRoundedIcon from "./tickRoundedIcon.svg";
import TickRoundedSolidIcon from "./tickRoundedSolidIcon.svg";
import TwitterIcon from "./twitterIcon.svg";
import UserIcon from "./userIcon.svg";
import VerticalThinkNewNZLogo from "./verticalThinkNewNZLogo.svg";
import WarningIcon from "./warningIcon.svg";
import WarningRoundedIcon from "./warningRoundedIcon.svg";
import WeChatIcon from "./weChatIcon.svg";
import WeiboIcon from "./weiboIcon.svg";
import YoutubeIcon from "./youtubeIcon.svg";
import ZoomIcon from "./zoomIcon.svg";

export {
  ArrowIcon,
  BackIcon,
  BlogIcon,
  CalendarIcon,
  ChatIcon,
  ChevronCircle,
  ChevronCircleLarge,
  ChevronIcon,
  CloseIcon,
  ContactIcon,
  CrossIcon,
  DashboardEnquiryIcon,
  DashboardEnquiryListIcon,
  DashboardRecommendationsCardIcon,
  DashboardRecommendationsCardIncompleteIcon,
  DashboardSavedIcon,
  DashboardSavedListIcon,
  EnquiredIcon,
  EnquiryCircle,
  EnquiryIcon,
  ExpandRoundedIcon,
  ExternalLinkIcon,
  FacebookIcon,
  FavouriteCircle,
  FavouriteIcon,
  FavouritedIcon,
  FilterIcon,
  FlagARIcon,
  FlagCNIcon,
  FlagESIcon,
  FlagJAIcon,
  FlagKOIcon,
  FlagNZIcon,
  FlagPTIcon,
  FlagTHIcon,
  FlagVNIcon,
  HamburgerMenuIcon,
  HidePasswordIcon,
  InfoIcon,
  InstagramIcon,
  LightBulbIcon,
  LocationIcon,
  LoginPrompt,
  LoginPromptMobile,
  LogoutIcon,
  MapLocationIcon,
  MinusIcon,
  NZGovtLogo,
  NZMapIcon,
  NaverIcon,
  PlayIcon,
  PlayIconSolid,
  PlusIcon,
  QuoteIcon,
  RecommendationsIcon,
  SavedIcon,
  SavedPrompt,
  SavedPromptMobile,
  ScrollIcon,
  SearchIcon,
  ShowPasswordIcon,
  TickIcon,
  TickRoundedIcon,
  TickRoundedSolidIcon,
  TwitterIcon,
  UserIcon,
  VerticalThinkNewNZLogo,
  WarningIcon,
  WarningRoundedIcon,
  WeChatIcon,
  WeiboIcon,
  YoutubeIcon,
  ZoomIcon,
};
