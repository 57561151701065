import { Box, Container, Flex, GridItem, Link, Text } from '@chakra-ui/react'
import { captureMessage } from '@sentry/nextjs'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { GridLayout, H1 } from 'ui'
import { CtaButton } from '../buttons'

interface ErrorProps {
  preview: boolean
  title?: string
  statusCode: number
}

const statusCodes: { [code: number]: string } = {
  400: 'Bad Request',
  404: 'Page Not Found',
  405: 'Method Not Allowed',
  500: 'Internal Server Error'
}

const Error = ({ title, statusCode }: ErrorProps) => {
  const { asPath } = useRouter()
  const pageTitle =
    title || statusCodes[statusCode] || 'An unexpected error has occurred'

  if (statusCode !== 404) {
    captureMessage(`Error Component: ${pageTitle}: ${asPath}`)
  }

  return (
    <>
      <NextSeo title={pageTitle} />
      <Container
        mb={{
          base: '80px',
          sm: '230px',
          lg: 0
        }}
        data-testid='error-component'
      >
        <GridLayout>
          <GridItem
            colSpan={{ base: 4, md: 8, lg: 6 }}
            mx={{ base: -6, lg: 0 }}
          >
            <Box
              boxSize='full'
              minHeight={{
                base: '210px',
                sm: '320px',
                md: '432px',
                lg: 'calc(100vh - 76px)',
                xl: 'calc(100vh - 92px)'
              }}
              bgImage={'url(/images/img-not-found-lg.jpg)'}
              bgPosition={{ base: 'center 80%', lg: 'center bottom' }}
              bgRepeat='no-repeat'
              bgSize={{ base: 'cover' }}
            ></Box>
          </GridItem>
          <GridItem colSpan={{ base: 4, md: 8, lg: 6 }}>
            <Flex
              align='center'
              height='full'
              maxW={{ base: '560px', lg: '424px' }}
              mx={{ base: 0, lg: 'auto' }}
              mt={{ base: 4, lg: 0 }}
            >
              <Box>
                <H1 fontSize='32px' lineHeight='44px' mb={4}>
                  {statusCode} {pageTitle}
                </H1>
                <Text>
                  Sorry, we can’t seem to find the page you’re looking for. In
                  the meantime, try to clear your cache and entering the URL
                  again or head to the{' '}
                  <Link
                    href='/'
                    title='Link to homepage'
                    aria-label='Link to homepage'
                  >
                    homepage
                  </Link>
                  .
                </Text>
                <CtaButton aria-label='Home page' href='/'>
                  Home page
                </CtaButton>
              </Box>
            </Flex>
          </GridItem>
        </GridLayout>
      </Container>
    </>
  )
}

export default Error
