import { GridItem, GridItemProps, Stack } from '@chakra-ui/react'
import { ReactElement, ReactNode } from 'react'
import { ContainerWrapper, GridLayout, H2, H3 } from 'ui'
import { colourMap, getPageThemeCtaColour } from 'utils'
import { CallToAction, CtaButton } from '../../buttons'
import { ThemeBackgroundGraphic } from '../../ThemeBackgroundGraphic'
export interface CtaButtonType extends CallToAction {
  type?: 'primary' | 'secondary'
}

interface GenericTextProps {
  title: string
  primaryButton?: CtaButtonType | null
  secondaryButton?: CtaButtonType | null
  children: ReactNode
  isIntroGenericText?: boolean
  pageTheme?: string
  themeBackgroundPosition?: string
  id?: string
  leadCaptureFormId?: string
  contentAlignment?: 'left' | 'center'
  backgroundColour?: string | null
  textColor?: string | null
}

const GenericText = ({
  title,
  primaryButton,
  secondaryButton,
  children,
  isIntroGenericText = false,
  pageTheme,
  themeBackgroundPosition,
  id,
  leadCaptureFormId,
  contentAlignment,
  backgroundColour,
  textColor
}: GenericTextProps): ReactElement => {
  const slug = title
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator

  const renderCtaButtons = (
    primaryButton: CtaButtonType | null | undefined,
    secondaryButton: CtaButtonType | null | undefined,
    alignment?: 'left' | 'center'
  ) => {
    const ctaColour = getPageThemeCtaColour(pageTheme)
    return (
      <Stack
        spacing={4}
        pt={[2, 3, 5]}
        mx={alignment == 'center' ? 'auto' : ''}
        maxW='fit-content'
        direction={{ base: 'column', lg: 'row' }}
      >
        {primaryButton?.label && (
          <CtaButton
            colorScheme={
              pageTheme && pageTheme !== 'default' && backgroundColour
                ? ctaColour
                : 'teal'
            }
            variant={pageTheme && pageTheme !== 'default' ? 'outline' : 'solid'}
            href={primaryButton.href}
            openInNewWindow={primaryButton.openInNewWindow}
            isExternalLink={primaryButton.isExternalLink}
            externalIconSize={6}
            aria-label={primaryButton.label}
            modalId={primaryButton.modalId}
            leadCaptureFormId={leadCaptureFormId}
            modalName={title}
          >
            {primaryButton.label}
          </CtaButton>
        )}

        {secondaryButton?.label && (
          <CtaButton
            colorScheme={
              pageTheme && pageTheme !== 'default' ? ctaColour : 'teal'
            }
            variant='outline'
            href={secondaryButton.href}
            openInNewWindow={secondaryButton.openInNewWindow}
            isExternalLink={secondaryButton.isExternalLink}
            externalIconSize={6}
            aria-label={secondaryButton.label}
            modalId={secondaryButton.modalId}
            leadCaptureFormId={leadCaptureFormId}
            modalName={title}
          >
            {secondaryButton.label}
          </CtaButton>
        )}
      </Stack>
    )
  }

  const gridItemTwoProps: GridItemProps = {
    ...(isIntroGenericText
      ? {
          colSpan: 6,
          fontSize: { base: '20px', md: '22px' },
          sx: {
            p: {
              lineHeight: ['30px', '36px']
            }
          }
        }
      : { colSpan: { base: 4, md: 8 }, colStart: { base: 1, lg: 3 } })
  }

  const hasThemeBackgroundGraphic: boolean =
    pageTheme && themeBackgroundPosition ? true : false

  return (
    <ContainerWrapper
      bgColor={backgroundColour ? colourMap(backgroundColour) : ''}
      position='relative'
      id={id}
      {...(hasThemeBackgroundGraphic
        ? {
            sx: {
              py: { base: '60px', lg: '120px' },
              mx: {
                base: 0,
                lg: 'auto'
              }
            }
          }
        : {})}
    >
      {hasThemeBackgroundGraphic && (
        <ThemeBackgroundGraphic
          theme={pageTheme}
          alignment={themeBackgroundPosition}
        />
      )}
      <GridLayout
        position='relative'
        zIndex={1}
        color={
          isIntroGenericText && !backgroundColour && textColor
            ? textColor
            : 'deepblue.500'
        }
        textAlign={contentAlignment}
      >
        {isIntroGenericText && title && (
          <GridItem colSpan={6}>
            <H3 as='h2' id={slug}>
              {title}
            </H3>
          </GridItem>
        )}
        <GridItem {...gridItemTwoProps}>
          {!isIntroGenericText && title && <H2 id={slug}>{title}</H2>}
          {children}
          {(primaryButton || secondaryButton) &&
            renderCtaButtons(primaryButton, secondaryButton, contentAlignment)}
        </GridItem>
      </GridLayout>
    </ContainerWrapper>
  )
}

export default GenericText
