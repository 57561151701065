import { TranslationQuery } from 'next-translate'
import useUnsafeTranslation from 'next-translate/useTranslation'

/**
 * Import locale strings from default locale
 */
import forms from '../../locales/en/forms.json'
import homepage from '../../locales/en/homepage.json'
import navigation from '../../locales/en/navigation.json'
import search from '../../locales/en/search.json'

type Join<S1, S2> = S1 extends string ? (S2 extends string ? `${S1}.${S2}` : never) : never

export type Paths<T> = {
  [K in keyof T]: T[K] extends Record<string, unknown> ? Join<K, Paths<T[K]>> : K
}[keyof T]

export type TranslationKeys = {
  forms: Paths<typeof forms>
  homepage: Paths<typeof homepage>
  navigation: Paths<typeof navigation>
  search: Paths<typeof search>
}

/**
 * `useTranslation` hook
 *
 * Our own custom hook for useTranslation that includes typesafety to ensure our strings are matching what we have defined in our translation files
 *
 * There is an issue on Github for this: https://github.com/aralroca/next-translate/issues/721
 *
 * TODO: Remove this when the issue is resolved
 */
export const useTranslation = <T extends keyof TranslationKeys>(ns: T) => {
  const { t, lang } = useUnsafeTranslation(ns)

  return {
    t: (
      s: TranslationKeys[T],
      q?: TranslationQuery,
      o?: {
        returnObjects?: boolean
        fallback?: string | string[]
        default?: string
      }
    ) => t(s, q, o),
    lang,
  }
}
