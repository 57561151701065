import { connectCurrentRefinements } from './Algolia'
import DashboardPagination from './DashboardPagination'
import DependantRefinementList from './DependantRefinementList'
import FacetRefinement, {
  StaticFacetRefinement,
  StaticItemRefinementList
} from './FacetRefinement'
import FacetSelect from './FacetSelect'
import FilterAccordionItem from './FilterAccordionItem'
import FilterButton from './FilterButton'
import FinderDetailBanner from './FinderDetailBanner'
import FinderFacetMenu, { FinderFacetMenuItem } from './FinderFacetMenu'
import FinderFilterPills from './FinderFilterPills'
import FinderResultItem, {
  DetailItem,
  FinderResultIntroLength
} from './FinderResultItem'
import FinderResults from './FinderResults'
import FinderWidget from './FinderWidget'
import { finderBoxShadow } from './helpers/finderBoxShadow'
import InstantSearchWrapper from './InstantSearchWrapper'
import Pagination from './Pagination'
import PopularSearches from './PopularSearches'
import ResultFilters from './ResultFilters'
import ResultsFacetMenu from './ResultsFacetMenu'
import ResultsFilterModal from './ResultsFilterModal'
import ResultsSearchBox from './ResultsSearchBox'
import SearchAutocomplete, {
  BaseResult,
  searchPlugins
} from './SearchAutocomplete'
import SearchButton from './SearchButton'
import SearchInputIcon from './SearchInputIcon'
import SortBy from './SortBy'

export * from './FilterDisplayLogic'
export {
  connectCurrentRefinements,
  DashboardPagination,
  DependantRefinementList,
  FacetRefinement,
  FacetSelect,
  FilterAccordionItem,
  FilterButton,
  finderBoxShadow,
  FinderDetailBanner,
  FinderFacetMenu,
  FinderFilterPills,
  FinderResultIntroLength,
  FinderResultItem,
  FinderResults,
  FinderWidget,
  InstantSearchWrapper,
  Pagination,
  PopularSearches,
  ResultFilters,
  ResultsFacetMenu,
  ResultsFilterModal,
  ResultsSearchBox,
  SearchAutocomplete,
  SearchButton,
  SearchInputIcon,
  searchPlugins,
  SortBy,
  StaticFacetRefinement
}
export type {
  BaseResult,
  DetailItem,
  FinderFacetMenuItem,
  StaticItemRefinementList
}
