import { Box } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { DisplayType } from 'ui'
import { colourBgMap } from 'utils'

export const getHeroIntroWrapperBgColor = ({
  heroType,
  displayType,
  backgroundColor,
  textColor,
  isDesktop,
  nextElementBackgroundColor
}: {
  heroType?: string | null
  displayType?: string | null
  backgroundColor?: string
  textColor?: string | null
  isDesktop?: boolean
  nextElementBackgroundColor?: string
}) => {
  if (textColor === 'white') {
    if (
      isDesktop &&
      heroType === 'SWNZ_HeroFeatured' &&
      displayType &&
      displayType.includes('finder') &&
      nextElementBackgroundColor
    ) {
      return nextElementBackgroundColor
    }

    return 'deep-blue'
  }

  if (heroType === 'SWNZ_HeroFeatured') {
    return 'surface-blue'
  }

  if (backgroundColor && displayType === DisplayType.FullBleed.toString()) {
    return backgroundColor
  }

  return undefined
}

export default function HeroIntroWrapper({
  children,
  backgroundColor
}: {
  children: ReactElement | ReactElement[]
  backgroundColor?: string
}) {
  const bgColor = backgroundColor ? colourBgMap(backgroundColor) : undefined
  return <Box backgroundColor={bgColor}>{children}</Box>
}
