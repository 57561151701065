import { Box } from '@chakra-ui/react'
import { Hero_Swnz_HeroFragment } from 'content-service'
import { ReactElement } from 'react'
import {
  createCtaButton,
  createHeroImageProps,
  createHeroVideoProps,
  IMAGE_SIZE_CONFIG
} from 'utils'
import { OptimisedImage } from '../../components'
import {
  CampaignHero,
  CampaignHeroProps,
  FullBleedHero,
  FullBleedHeroProps,
  ReducedHero,
  ReducedHeroProps
} from '../../components/content-components'

type Hero = Hero_Swnz_HeroFragment & {
  pageTheme?: string
  leadCaptureFormId?: string
}

interface HeroSelectProps {
  displayType: string
  hero?: Hero
  pageTheme?: string
  leadCaptureFormId?: string
}

export enum DisplayType {
  FullBleed = 'full-bleed',
  Reduced = 'reduced',
  Campaign = 'campaign'
}

interface HeroComponentMap {
  [displayType: string]: (
    hero: Hero,
    leadCaptureFormId?: string
  ) => ReactElement
}

const createFullBleedHeroComponentProps = (hero: Hero): FullBleedHeroProps => ({
  heading: hero?.heading || '',
  backgroundColour: hero?.backgroundColour || '',
  heroBackgroundVideoUrl: hero?.heroBackgroundVideo?.url || '',
  heroBackgroundVideoTitle: hero?.heroBackgroundVideo?.title || '',
  heroBackgroundVideoDescription: hero?.heroBackgroundVideo?.description || '',
  primaryCta: createCtaButton(hero?.primaryCta),
  video: createHeroVideoProps(hero?.mediaItem),
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FULLBLEED_HERO,
    objectPosition: '80%'
  }),
  leadCaptureFormId: hero?.leadCaptureFormId || ''
})

const createCampaignHeroComponentProps = (hero: Hero): CampaignHeroProps => ({
  heading: hero?.heading || '',
  heroBackgroundVideoUrl: hero?.heroBackgroundVideo?.url || '',
  heroBackgroundVideoTitle: hero?.heroBackgroundVideo?.title || '',
  heroBackgroundVideoDescription: hero?.heroBackgroundVideo?.description || '',
  video: createHeroVideoProps(hero?.mediaItem),
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FULLBLEED_HERO,
    objectPosition: '80%'
  }),
  pageTheme: hero?.pageTheme ?? '',
  leadCaptureFormId: hero?.leadCaptureFormId || ''
})

const createReducedHeroComponentProps = (hero: Hero): ReducedHeroProps => ({
  heading: hero?.heading || '',
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.REDUCDED_HERO
  })
})

const heroComponentMap: HeroComponentMap = Object.freeze({
  [DisplayType.FullBleed]: function renderHeroComponent(hero) {
    return <FullBleedHero {...createFullBleedHeroComponentProps(hero)} />
  },
  [DisplayType.Campaign]: function renderHeroComponent(hero) {
    return <CampaignHero {...createCampaignHeroComponentProps(hero)} />
  },

  [DisplayType.Reduced]: function renderHeroComponent(hero) {
    return <ReducedHero {...createReducedHeroComponentProps(hero)} />
  }
})

const HeroSelect = ({
  displayType,
  hero,
  pageTheme,
  leadCaptureFormId
}: HeroSelectProps): ReactElement | null => {
  if (!hero || !heroComponentMap[displayType]) {
    return null
  }

  return (
    <Box>
      {heroComponentMap[displayType]({
        ...hero,
        pageTheme,
        leadCaptureFormId
      })}
    </Box>
  )
}

export default HeroSelect
